import React from "react"
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LineIcon,
  LineShareButton,
} from "react-share"

const ShareButtonList = ({ title, url, description }) => {
  const iconSize = 32

  return (
    <>
      <h3>SHARE</h3>
      <div className="social-links">
        <div className="social-links__icon">
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon round size={iconSize} />
          </TwitterShareButton>
        </div>
        <div className="social-links__icon">
          <FacebookShareButton url={url} quote={description}>
            <FacebookIcon round size={iconSize} />
          </FacebookShareButton>
        </div>
        <div className="social-links__icon">
          <LineShareButton url={url} quote={description}>
            <LineIcon round size={iconSize} />
          </LineShareButton>
        </div>
      </div>
    </>
  )
}

export default ShareButtonList
